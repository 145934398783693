import * as React from "react"
import Learn from "../components/learn"

import Layout from "../components/layout"
import Seo from "../components/seo"

const Company = () => (
  <Layout>
    <Seo title="Company" />
    <div
      style={{
        display: `flex`,
        backgroundImage: `url("https://s3-alpha-sig.figma.com/img/14dd/db98/447d8b1c5994eb67ac9d219155b00934?Expires=1620604800&Signature=M98wLBx3ASx7ekmeazwfhtUqnzCOAN0QwBb-OnH~Z6Xn47s2HuwBCpZsr0rzYHvRf4C7smB2KlWVxt9ImuY~Y~fUz0pbKKzsx2q0OyXhxvUoaafvJurxA-N4mqO6O7pbpnk9XFaKADO68QtvR~BLLLB3l1hV7J1pc5lbYMSBmWodpPuLSJbpX75WAEMZTiQdMfaCqZtfCpKQeHhXRe4YOwM7BBdClDrJMgKgOuPZ5PlQUhBsDFj023stN8sR0LDzhYp8ZaIj0D-EtUi8QBlTz6ktC-Zvx5W56SwyscpQb7yqnvJQ4ZQsiNzDmgEOstXKSFT6b6Aw7UnIjM0mg0-V7g__&Key-Pair-Id=APKAINTVSUGEWH5XD5UA")`,
        // backgroundImage: `url("src/images/people-hero.jpeg")`,
        backgroundSize: `cover`,
        backgroundRepeat: `no-repeat`,
        color: `white`,
        height: `45vh`,
        justifyContent: `center`,
        alignItems: `center`,
      }}
    ></div>
    <div
      style={{
        display: `flex`,
        flexDirection: `column`,
        alignItems: `center`,
        justifyContent: `center`,
        height: `80vh`,
        padding: `20vw`,
        textAlign: `center`,
      }}
    >
      <h1>Our Story</h1>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Id ut dolor
        egestas consequat, vestibulum scelerisque dignissim vel dui. Nibh
        suscipit cras at facilisi sed nulla. Dui odio iaculis proin augue in
        orci. Sem eu vitae, feugiat ullamcorper mauris eu semper. Sed
        scelerisque amet cras a sit. Dolor fringilla montes, sit lacus. Netus
        tellus, enim iaculis feugiat diam, pharetra imperdiet molestie. Amet
        pharetra dolor id mollis aliquam fermentum mi lacus. Est risus,
        malesuada eget imperdiet sit molestie imperdiet. In lorem dui morbi enim
        arcu. Mauris sit praesent gravida quisque sit. Sit gravida turpis eget
        duis leo pharetra, nisi eros. Duis dolor eget turpis dui lacus
        consectetur egestas rutrum. Volutpat lacus, ac sit tortor habitant lorem
        commodo vulputate. Nunc, massa fermentum aliquet arcu varius a,
        vestibulum. Ut suspendisse porttitor diam aliquam lorem libero. Ut sit
        sit sit non dui rhoncus volutpat malesuada velit. Suspendisse rhoncus,
        donec quam sed sollicitudin arcu habitant ultricies. Facilisi sit tellus
        libero tempor ac auctor augue. Mollis semper faucibus neque enim posuere
        hac. Vitae ultrices integer at integer.
      </p>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Id ut dolor
        egestas consequat, vestibulum scelerisque dignissim vel dui. Nibh
        suscipit cras at facilisi sed nulla. Dui odio iaculis proin augue in
        orci. Sem eu vitae, feugiat ullamcorper mauris eu semper. Sed
        scelerisque amet cras a sit. Dolor fringilla montes, sit lacus. Netus
        tellus, enim iaculis feugiat diam, pharetra imperdiet molestie. Amet
        pharetra dolor id mollis aliquam fermentum mi lacus. Est risus,
        malesuada eget imperdiet sit molestie imperdiet. In lorem dui morbi enim
        arcu. Mauris sit praesent gravida quisque sit. Sit gravida turpis eget
        duis leo pharetra, nisi eros. Duis dolor eget turpis dui lacus
        consectetur egestas rutrum. Volutpat lacus, ac sit tortor habitant lorem
        commodo vulputate. Nunc, massa fermentum aliquet arcu varius a,
        vestibulum. Ut suspendisse porttitor diam aliquam lorem libero. Ut sit
        sit sit non dui rhoncus volutpat malesuada velit. Suspendisse rhoncus,
        donec quam sed sollicitudin arcu habitant ultricies. Facilisi sit tellus
        libero tempor ac auctor augue. Mollis semper faucibus neque enim posuere
        hac. Vitae ultrices integer at integer.
      </p>
    </div>
    <Learn></Learn>
    <Learn></Learn>
    <Learn></Learn>
  </Layout>
)

export default Company
